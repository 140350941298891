import { DISCARD_EXCEPTION, RENDER_EXCEPTION } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case RENDER_EXCEPTION: {
      const exception = action.payload;
      return {
        ...state,
        message: exception.message,
        errors: exception.errors,
        show: true,
      };
    }
    case DISCARD_EXCEPTION: {
      return {
        ...state,
        show: false,
      };
    }
    default:
      return state;
  }
}
